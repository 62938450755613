import React from 'react';

const ConfirmedAccount = () => {

  const style = {
    bg_picture: { background: 'url(./imgs/bgsections-details.jpg)' }
  }

  return (
    <section className="wrapper_sections hg_top">
      <h1 className="ttls fw700 text-center mb-4 font-italic">¡Cuenta <span className="c_primary">verificada</span>!</h1>
      <div className="contain_sections">
        <div className="row">
          <div className="col-6 ">
            <div className="box_info_sections">
              <h5>Gracias por verificar tu cuenta.</h5>
              <hr />
              <h6 className="fw300">Ya eres parte de la comunidad Red Voucher Online, descubre los beneficios exclusivos que tenemos para ti y <span className="c_primary fw500">disfruta de todos los días como si fueran viernes.</span> </h6>
              <p className="mt-4">

                {/* <button className="btn"
                  onClick={toggle}
                >
                  Iniciar sesión</button> */}
              </p>
            </div>
          </div>
          <div className="col-6">
            <div
              className="box_picture"
              style={style.bg_picture}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ConfirmedAccount;
