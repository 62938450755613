import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { connect } from 'react-redux';
import { setCountrySetting, setWhatsApp } from '../store/actions';
import swal from 'sweetalert';
import BlockScreen from '../components/blockScreen';
import { InstallPWA } from '../components/pwa/installPWA';
import WhatsAppButton from '../components/whatsAppButton';


class Layout extends Component {
    static displayName = Layout.name;
    constructor() {
        super()

        this.state = {
            settings: ""
        }
    }

    componentWillMount() {
        this.getBrowser();
        this.settings();
        if (localStorage.getItem('pruchaseList') == undefined) localStorage.setItem('pruchaseList', "[]");
        if (localStorage.getItem('pruchaseTotal') == undefined) localStorage.setItem('pruchaseTotal', 0);
    }

    getBrowser = () => {
        try {
            let sBrowser, isSupported, sUsrAg = navigator.userAgent;
            // The order matters here, and this may report false positives for unlisted browsers.

            if (sUsrAg.indexOf("Firefox") > -1) {
                sBrowser = "Mozilla Firefox";
                isSupported = true;
                // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
            } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
                sBrowser = "Samsung Internet";
                isSupported = false;
                // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
            } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
                sBrowser = "Opera";
                isSupported = true;
                // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
            } else if (sUsrAg.indexOf("Trident") > -1) {
                sBrowser = "Microsoft Internet Explorer";
                isSupported = false;
                // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
            } else if (sUsrAg.indexOf("Edge") > -1) {
                sBrowser = "Microsoft Edge";
                isSupported = true;
                // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
            } else if (sUsrAg.indexOf("Chrome") > -1) {
                sBrowser = "Google Chrome or Chromium";
                isSupported = true;
                // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
            } else if (sUsrAg.indexOf("Safari") > -1) {
                sBrowser = "Apple Safari";
                isSupported = true;
                // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
            } else {
                sBrowser = "unknown";
                isSupported = false;
            }

            if (!isSupported) {
                swal({ title: "¡Para una mejor experiencia!", text: "Te recomendamos iniciar sesión en los siguientes navegadores: Google Chrome, Firefox, Opera, Microsoft Edge", icon: "warning" });
            }
        } catch (error) {
            swal({ title: "¡Para una mejor experiencia!", text: "Te recomendamos iniciar sesión en los siguientes navegadores: Google Chrome, Firefox, Opera, Microsoft Edge", icon: "warning" });
        }
    }

    settings = () => {
        fetch("/api/Account/Settings" + window.location.search, { method: 'GET' })
            .then(response => response.json())
            .then(result => {
                this.validateCountry(result.LandingCountry);
                this.setState({ settings: result });
                this.props.setCountrySetting({ cultureCode: result.CultureCode, isoCurrency: result.ISOCurrency, currency: result.Currency });
                this.props.setWhatsApp(result.WhatsApp);
            })
            .catch(error => console.log('error', error));
    }

    validateCountry = (landingCountry) => {
        if (landingCountry.Redirect) {
            window.location.href = landingCountry.UrlRedirect;
        } else if (landingCountry.ShowAlert) {
            swal({
                title: `Estás en Red Voucher Online ${landingCountry.CountryDefault}, pero tu país actual es ${landingCountry.CountryNameIp}`,
                text: "¿Te redirijo a Red Voucher Online de tu país?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then(function (willDelete) {
                    if (willDelete) {
                        if (typeof window !== "undefined") {
                            window.location.href = landingCountry.UrlEcommerce;
                        }
                    }
                }.bind(this));
        }
    }

    render() {
        return <React.Fragment>
            <BlockScreen />
            <Header {...this.state.settings} />
            <main>
                {this.props.children}
            </main>
            < InstallPWA />
            <Footer {...this.state.settings} />
            <InstallPWA />
            <WhatsAppButton />
        </React.Fragment>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCountrySetting: (countrySetting) => {
            dispatch(setCountrySetting(countrySetting))
        },
        setWhatsApp: (phone) => {
            dispatch(setWhatsApp(phone))
        }
    }
}

export default connect(null, mapDispatchToProps)(Layout);