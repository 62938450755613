import { registerInterceptor } from "../fetchIntercept";

/**
 * Communication with the API
 * @param {*} url
 * @param {*} method
 * @param {*} body
 * @param {*} contentType
 */

export const callApi = async (url, method, body) => {
    var options = {
        method: method,
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    return fetch(`/api${url}`, options)
        .then((response) => handleResponse(response, false))
        .catch(handleError);
};

const handleResponse = (response, isFileResponse) => {
    if (!isFileResponse) {
        if (response.ok) {
            return response.text().then((text) => {
                let data;

                try {
                    data = text && JSON.parse(text);
                } catch (e) {
                    data = text;
                }

                if (response.redirected) window.location.href=  response.url;
                return data;
            });
        }

        return {
            statusCode: response.status,
            errorMessage: `Status code ${response.status}`,
        };
    }
    else return response.blob();
};

const handleError = (error) => {
    console.log("error", error);
    if (error.message === "Failed to fetch")
        error.message = "El servicio no se encuentra disponible temporalmente";
    return Promise.reject(error);
};
