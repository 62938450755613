import React, { Component, useState } from 'react';
import { useForm } from 'react-hook-form'
import swal from "sweetalert"
import { useSelector, useDispatch } from "react-redux";
import { Badge } from 'react-bootstrap';

import { accountService } from "../services/account.service";
import { getUser } from "../store/actions";

const UserProfile = () => {
    const dispatch = useDispatch();
    const { firstName, lastName } = useSelector(state => state.user);
    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = async (data, e) => {
        const result = await accountService.updateDataProfile(data);
        if (result.StatusCode == 200) {
            dispatch(getUser());
            swal({ text: "Los datos de perfil se actualizaron correctamente", icon: "success" });
        } else {
            swal({ text: result.Message, icon: "error" });
        }
    }

    const style = {
        bg_picture: { background: 'url(./imgs/bg_editprofile.jpg)' }
    }

    return (
        <section className="wrapper_sections hg_top">
            <h1 className="ttls fw700 text-center mb-1 font-italic c_primary">Mi perfil</h1>
            <div className="contain_sections">
                <div className="row">
                    <div className="col-6 ">
                        <div className="box_info_sections">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group mb-4">
                                    <label for="email_user">Nombre(s)</label>
                                    <input type="text" className="form-control" id="firstName" defaultValue={firstName} aria-describedby="fistName" {...register("firstName", { required: true })} />
                                    {errors.firstName && <Badge variant="danger">Nombre requerido</Badge>}
                                </div>
                                <div className="form-group mb-4">
                                    <label for="email_user">Apellidos</label>
                                    <input type="text" className="form-control" id="lastName" defaultValue={lastName} aria-describedby="lastName" {...register("lastName", { required: true })} />
                                    {errors.lastName && <Badge variant="danger">Apellido requerido</Badge>}
                                </div>

                                <button type="submit" className="btn">Guardar</button>
                            </form>
                        </div>

                    </div>
                    <div className="col-6">
                        <div
                            className="box_picture"
                            style={style.bg_picture}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default UserProfile;
