import React, { useState } from 'react';
import PurchasedItem from '../purchasedItem';
import { PaginatedPage } from './pagination';
import './shoppingHistoryWrapper.scss'

const ShoppingHistoryWrapper = ({ shoppingHistory }) => {
    const [page, setPage] = useState(1);
    const totalPages = shoppingHistory.length > 0 ? shoppingHistory.length : 1;

    const style = {
        bgProviders: { background: 'url(./imgs/bgsections-details.jpg)' }
    }

    return (
        <div className="wrapper_shoppingHistory ">
            <div className="bg_sections" style={style.bgProviders}></div>

            <div className="contain_shop_history">
                <h2 className="ttls_sections_shop">Mis compras</h2>
                <div className="boxes_hop_history">
                    {
                        shoppingHistory.length > 0
                            ? shoppingHistory[page - 1].map(item => {
                                return <PurchasedItem {...item} />
                            })
                            : ""
                    }
                    <div className="paginated_page">
                        <PaginatedPage
                            totalPages={totalPages}
                            currentPage={page}
                            onChange={page => setPage(page)}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ShoppingHistoryWrapper;
