import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import './sliderHome.scss';
import { connect } from 'react-redux';
import { setModal } from '../../store/actions';
class SliderHome extends Component {
    static displayName = SliderHome.name;
    constructor(props) {
        super(props)
        this.state = {
            slider: props.carrousel
        }
    }

    componentWillReceiveProps(next_props) {
        this.setState({ slider: next_props.carrousel });
    }

    validateSetModal = (idBan) => {
        var url = "/api/Home/ClickBanner?idBanner=" + idBan;
         if (!this.props.isAuthenticated) {
                 this.props.setModal();
             fetch(url,
                 {
                     method: 'POST',
                     headers: {
                         'Content-Type': 'application/json'
                     },
                     body: idBan,
                 }).then(function (response) {
                     if (response.ok) {
                         return response.json()
                     }
                 }).then(function (result) {

                     if (result.Status == true) {

                     }

                 }).catch(function (error) {

                 });
             }
    }

    //validateSetModal = (idBan) => {
    //    console.log(idBan);
    //    console.log("Hola");
    //    if (!this.props.isAuthenticated) {
           
    //        this.props.setModal();
    //        fetch('/api/Home/ClickLogin', {
    //            method: 'GET',
    //            headers: {
    //                'Content-Type': 'application/json'
    //            }
    //        }).then(function (response) {
    //            if (response.ok) {
    //                return response.json()
    //            }
    //        }).then(function (result) {

    //            if (result.Status == true) {
    //                console.log(result);
    //            }

    //        }).catch(function (error) {
    //            console.log(error);
    //        });
    //    }

    //}






    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 500,
            centerPadding: "60px",
            autoplaySpeed: 3000,
            pauseOnHover: true,
            swipeToSlide: true,
            className: "center",
            centerMode: true,
            responsive: [
                {
                    breakpoint: 760,
                    settings: {
                        centerPadding: "30px",
                    },
                },
            ],
        };

        return (
            <div className="wrapper_slider_home">
                <Slider {...settings}>
                    {
                        this.state.slider.map(item => {
                            if (item.Link.indexOf("http://") == 0 || item.Link.indexOf("https://") == 0) {
                                return <div>
                                    <a rel="nofollow" href={"/api/Home/Hub2?idBanner=" + item.CompanyCarrouselId + "&url=" + item.Link}  id="enlace" class="automatic" >
                                        <img className="sld_desktop" src={item.UrlImage} alt=""  />
                                        <img className="sld_mobile" src={item.UrlImageResponsive} alt="" />
                                    </a>
                                </div>
                            }


                            if (this.props.isAuthenticated == false) {
                                return <Link to={this.props.isAuthenticated ? item.Link : ""} onClick={() => this.validateSetModal(item.CompanyCarrouselId)}>
                                    <img className="sld_desktop" src={item.UrlImage} alt="" />
                                    <img className="sld_mobile" src={item.UrlImageResponsive} alt="" />
                                </Link>
                            }

                            if (this.props.isAuthenticated == true) {
                                return <div>
                                    <a rel="nofollow" href={"/api/Home/Hub2?idBanner=" + item.CompanyCarrouselId + "&url=" + window.location.origin + item.Link} id="enlace" class="automatic" >
                                        <img className="sld_desktop" src={item.UrlImage} alt="" />
                                        <img className="sld_mobile" src={item.UrlImageResponsive} alt="" />
                                     </a>

                                </div>
                            }
                            
                           
                        })
                    }
                </ Slider>
            </div>

        )
    }
}



const mapStateToProps = (state) => {
    return { isAuthenticated: state.user.isAuthenticated }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModal: () => {
            dispatch(setModal())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SliderHome)