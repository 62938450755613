import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import "./footer.scss";

const Footer = (props) => {

    const [currentYear, setCurrentYear] = useState(0);

    useEffect(() => {
        const year = new Date();
        setCurrentYear(year.getFullYear());
    }, []);

    return (
        <footer className="wrapper_footer">
            <div className="container-fluid">
                <div className="row ">
                    <div className="col-sm-4 box_sociales">
                        <h6>Síguenos en</h6>
                        <p>
                            <span>
                                <a target="_blank" href="https://www.facebook.com/redvoucher">
                                    <i className="fab fa-facebook-square"></i>
                                </a>
                            </span>
                            <span>
                                <a
                                    target="_blank"
                                    href="https://youtu.be/abxBj7LPcio"
                                >
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </span>
                            <span>
                                <a target="_blank" href="https://twitter.com/redvoucher">
                                    <svg style={{ margin: "0 0 6px 8px" }} viewBox="0 0 512 512" fill="#e94242" height="18px"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                                </a>
                            </span>
                        </p>
                    </div>
                    <div className="col-sm-4 box_legal">
                        <h6>Información legal</h6>
                        <div className="box_flex justify_around align_center">
                            <p>
                                <Link to="/notice-privacy" className="">
                                    Aviso de privacidad
                                </Link>
                            </p>
                            <p>
                                <Link to="/terms-and-conditions" className="">
                                    Términos y condiciones
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-4 box_contacto">
                        <h6>Contacto</h6>
                        <p>
                            <a href={"mailto: " + props.MailContact}>
                                <i className="fas fa-envelope"></i> {props.MailContact}
                            </a>
                        </p>
                    </div>
                </div>
                <div className="row pie">
                    <div className="col-sm-12">
                        <span>{currentYear} Red Voucher Online. Todos los derechos reservados.</span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
