import React from 'react';
import { useForm } from 'react-hook-form'
import swal from "sweetalert"
import { Badge } from 'react-bootstrap';

const ResetPassword = (props) => {
    const style = {
        bg_picture: { background: 'url(./imgs/bg_forgotpass.jpg)' }
    }

    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = (data, e) => {
        fetch('/api/Account/ResetPassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(function (response) {
            if (response.ok) {
                return response.json()
            }
        }).then(function (result) {
            if (result.Status) {
                e.target.reset();
                swal({ text: result.Message, icon: "success" });
            } else {
                swal({ text: result.Message, icon: "error" });
            }
        }).catch(function (error) {
            console.log(error);
            swal({ text: "Error", icon: "error" });
        });
    }

    return (
        <section className="wrapper_sections hg_top">
            <h1 className="ttls fw700 text-center mb-3 c_primary">Nueva contraseña</h1>
            <div className="contain_sections">
                <div className="row">
                    <div className="col-6 ">
                        <div className="box_info_sections">
                            <h6 className="fw300">Ingresa tu nueva contraseña</h6>
                            <hr />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input type="hidden" id="code" value={props.location.search.split('code=')[1]} {...register("Code", { required: true })} />
                                <div className="form-group mb-4">
                                    <label for="email_user">Correo electrónico</label>
                                    <input type="email" className="form-control" id="Email" aria-describedby="emailHelp" placeholder="example@mail.com" {...register("Email", {
                                        required: "Email requerido", pattern: { value: /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Email no valido" }
                                    })} />
                                    {errors.Email && <Badge variant="danger">{errors.Email?.message}</Badge>}
                                </div>
                                <div className="form-group mt-4 mb-4">
                                    <label for="new_password">Nueva contraseña</label>
                                    <input type="password" className="form-control" id="new_password" placeholder="Nueva contraseña" {...register("Password", { required: true })}></input>
                                    {errors.Password && <Badge variant="danger">Password requerido</Badge>}
                                </div>
                                <div className="form-group mt-4 mb-4">
                                    <label for="new_password">Confirmar nueva contraseña</label>
                                    <input type="password" className="form-control" id="new_password" placeholder="Confirmar cueva contraseña" {...register("ConfirmPassword", { required: true })}></input>
                                    {errors.ConfirmPassword && <Badge variant="danger">Campo requerido</Badge>}
                                </div>

                <button type="submit" className="btn ">Guardar contraseña</button>
              </form>
            </div>

                    </div>
                    <div className="col-6">
                        <div
                            className="box_picture"
                            style={style.bg_picture}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ResetPassword