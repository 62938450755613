import React from "react";
import "./recharge.scss";
import BoxPayMethod from '../boxPayMethod'
export function RechargeDetails({vSection, referenceData,selectedProduct}) {
  
  
  return (
    <form className="cont_recharge_form">
      <h6 className="recharge_ttl fw300 box_flex align_center mb-2">
        <span className="box_flex justify_center align_center dots_num check">
          <i className="fas fa-check"></i>
        </span>
        <span>Detalle de tu recarga</span>
      </h6>

        <div className="box_btn text-right">
          <span className="btn-form fw500" onClick={vSection}>Modificar</span>
        </div>
        <div className="px-4">
          <h5 className="fw600">
          { selectedProduct.name }
          </h5>
          <h6 className="text-muted reference fw300">
              {referenceData && referenceData.reference}
          </h6>
          <h6 className="fw500 mt-2 c_green">
            Monto a pagar: ${ selectedProduct.productPrice }
          </h6>
        </div>
      <div className="wrapper_paymethod mt-4">
          <h6 className="ttls_sections"><span>Elige tu método de pago</span></h6>
          <BoxPayMethod rechargeProducts={{...selectedProduct, reference: referenceData}} />
          <div className="box_powered_by">
              <small className="font-italic fw600">Powered by</small>
              <a target="_blank" href="https://redpay.mx">
                  <img src="./imgs/paymentMethod/red-pay-logo.png" alt="Red Pay Pasarela de pago, México" />
              </a>
          </div>
      </div>
    </form>
  );
}
